import React from "react";
import { Navigate } from "react-router-dom";

// Profile
import UserProfile from "../pages/Authentication/user-profile";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";

// Dashboard
import Dashboard from "../pages/Dashboard/index";
import { getUserRole } from "helpers/fakebackend_helper";
import Organizers from "pages/Organizers/Dashboard";

//Organizers Pages
import SponsorDashboard from "pages/Organizers/Dashboard";
import OrganizersBookings from "pages/Organizers/Bookings";
import OrganizersEvents from "pages/Organizers/Events";
import OrganizerList from "pages/Admin/Organizers/OrganizerList";
import EventList from "pages/Admin/Events/EventList";
import CreateEvent from "pages/Admin/Events/CreateEvent";
import EventTickets from "pages/Admin/Events/EventTickets";
import EventTicketByEvent from "pages/Admin/Events/EventTicketByEvent";
import ForumList from "pages/Admin/Forum/ForumList";
import Reports from "pages/Admin/Report/Reports";
import AttendeeReport from "pages/Admin/Report/AttendeeReport";
import EventSummary from "pages/Admin/Report/EventSummary";
import BookingAllStatus from "pages/Admin/Report/BookingAllStatus";
import OrganizerReports from "pages/Organizers/Report/OrganizerReports";
import OrganizerAttendeeReport from "pages/Organizers/Report/OrganizerAttendeeReport";
import OrganizerEventSummary from "pages/Organizers/Report/OrganizerEventSummary";
import EventVenue from "pages/Admin/Events/EventVenue";
import OrganizerEventTickets from "pages/Organizers/Events/OrganizerEventTickets";
import OrganizerEventVenue from "pages/Organizers/Events/OrganizerEventVenue";
import EditEvent from "pages/Admin/Events/EditEvent";




const role = getUserRole();
const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard /> },
  // { path: "/s-dashboard", component: <SponsorDashboard /> },
  { path: "/organizers", component: <OrganizerList /> },
  { path: "/admin/events", component: <EventList /> },
  { path: "/event/new", component: <CreateEvent /> },
  { path: "/admin/events/edit/:id", component: <EditEvent /> },
  { path: "/event-ticket", component: <EventTickets /> },
  { path: "/ticket-by-event/:slugId", component: <EventTicketByEvent /> },
  { path: "/forum", component: <ForumList /> },
  {path:"/reports",component:<Reports/>},
  {path:"/attendee-report",component:<AttendeeReport/>},
  {path:"/event-summery-report",component:<EventSummary/>},
  {path:"/booking-status-report",component:<BookingAllStatus/>},
  {path:"/event-venue",component:<EventVenue/>},
  



  


  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: role===1?<Navigate to="/dashboard" />:<Navigate to="/s-dashboard" />,
  },
];

const authSponsorProtectedRoutes = [
  { path: "/s-dashboard", component: <OrganizerAttendeeReport /> },
  { path: "/bookings", component: <OrganizersBookings /> },
  { path: "/organizer-events", component: <OrganizersEvents /> },
  { path: "/organizer-report", component: <OrganizerReports /> },
  { path: "/organizer-attendee-report", component: <OrganizerAttendeeReport /> },
  { path: "/organizer-event-summery-report", component: <OrganizerEventSummary /> },
  { path: "/organizer-events", component: <OrganizersEvents /> },
  { path: "/organizer-event-ticket", component: <OrganizerEventTickets /> },
  { path: "/organizer-event-venue", component: <OrganizerEventVenue /> },

  { path: "/profile", component: <UserProfile /> },
  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
 
];
const publicRoutes = [
  { path: "/login", component: <Login /> },
  { path: "/logout", component: <Logout /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/register", component: <Register /> },
];

export { authProtectedRoutes, publicRoutes,authSponsorProtectedRoutes };
